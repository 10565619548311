import './App.css';

import Home from "./pages/Home/Home"
import Footer from "./components/Footer/Footer"

function App() {
  return (
    <>
    <main>
      <Home />
    </main>
    <footer>
      <Footer />
    </footer>
    </>
  );
}

export default App;
