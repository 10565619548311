import React, { useEffect, useRef, useState } from "react";
import "./ServicesSection.css";

import foto1 from "../../assets/img/venta1.webp";
import foto2 from "../../assets/img/venta2.webp";
import foto3 from "../../assets/img/venta3.webp";
import foto30 from "../../assets/img/venta4.webp";
import foto31 from "../../assets/img/venta5.webp";

import foto4 from "../../assets/img/alquiler1.webp";
import foto5 from "../../assets/img/alquiler2.webp";
import foto6 from "../../assets/img/alquiler3.webp";
import foto7 from "../../assets/img/alquiler4.webp";
import foto8 from "../../assets/img/alquiler5.webp";
import foto19 from "../../assets/img/alquiler6.webp";
import foto20 from "../../assets/img/alquiler7.webp";
import foto21 from "../../assets/img/alquiler8.webp";
import foto22 from "../../assets/img/alquiler9.webp";
import foto32 from "../../assets/img/alquiler10.webp";
import foto33 from "../../assets/img/alquiler11.webp";

import foto9 from "../../assets/img/cercos1.webp";
import foto10 from "../../assets/img/cercos2.webp";

import foto12 from "../../assets/img/reparacion1.webp";
import foto13 from "../../assets/img/reparacion2.webp";
import foto14 from "../../assets/img/reparacion3.webp";
import foto15 from "../../assets/img/reparacion4.webp";
import foto27 from "../../assets/img/reparacion5.webp";
import foto28 from "../../assets/img/reparacion6.webp";
import foto29 from "../../assets/img/reparacion7.webp";

import foto16 from "../../assets/img/arenado1.webp";
import foto17 from "../../assets/img/arenado2.webp";
import foto18 from "../../assets/img/arenado3.webp";

import foto23 from "../../assets/img/cercos3.webp";
import foto24 from "../../assets/img/cercos4.webp";

import foto25 from "../../assets/img/arenado4.webp";
import foto26 from "../../assets/img/arenado5.webp";

const ServicesSection = ({ reference }) => {
  const [selectedService, setSelectedService] = useState(null);
  const servicesRef = useRef(null);

  const services = [
    {
      id: 1,
      title: "Venta",
      description:
        "Soluciones de equipos de calidad para su industria petrolera. Clickea para mas informacion!",
      descriptionService: [
        {
          content:
            "Amplia selección de equipos petroleros de alta calidad. Nuestra gama de productos incluye bombas, válvulas, tuberías, herramientas y más, diseñados para optimizar sus operaciones y mantener la eficiencia en su sitio.",
        },
      ],
      images: [foto1, foto3, foto2, foto30, foto31],
    },
    {
      id: 2,
      title: "Alquiler",
      description:
        "Flexibilidad a través del alquiler de equipos de vanguardia. Clickea para mas informacion!",
      descriptionService: [
        {
          content:
            "Desde maquinaria pesada hasta herramientas especializadas. Un catálogo de alquiler de equipos petroleros de última generación para la flexibilidad que su empresa necesita para optimizar sus operaciones.",
        },
      ],
      images: [foto4, foto5, foto7, foto19, foto20, foto21, foto22, foto32, foto33],
    },
    {
      id: 3,
      title: "Cercos perimetrales, olimpicos y rurales",
      description:
        "Protegiendo sus instalaciones petroleras con cercos de calidad. Clickea para mas informacion!",
      descriptionService: [
        {
          content:
            "Robustas barreras de seguridad, cercos olímpicos de alta protección y cercos rurales para áreas remotas están dentro de nuestro servicio de diseño e instalación de cercos que garantizan la protección y el control de acceso, manteniendo sus activos seguros.",
        },
      ],
      images: [foto9, foto10, foto23, foto24],
    },
    {
      id: 4,
      title: "Soldadura",
      description: "La unión resistente que su operación necesita. Clickea para mas informacion!",
      descriptionService: [
        {
          title: "Soldadura SMAW/ MMAW",
          content:
            "Con un equipo altamente calificado, experimentado y certificado, garantizamos trabajos de soldadura con la calidad necesaria para cubrir las exigencias de eficacia y eficiencia que nos demanda la industria.",
        },
      ],
      images: [foto15],
    },
    {
      id: 5,
      title: "Arenado",
      description: "Manteniendo superficies en óptimas condiciones. Clickea para mas informacion!",
      descriptionService: [
        {
          content:
            "Un servicio fundamental para la preservación de activos. Ofrecemos servicios de arenado y restauración de superficies para eliminar la corrosión y prepararlas para revestimientos protectores. Esto garantiza una mayor vida útil y durabilidad de sus equipos y herramientas.",
        },
      ],
      images: [foto16, foto17, foto18, foto25, foto26],
    },
    {
      id: 6,
      title: "Reparacion e IND herramientas de equipo",
      description:
        "Herramientas y equipos diseñados para la industria petrolera. Clickea para mas informacion!",
      descriptionService: [
        {
          content:
            "Recuperamos sus herramientas y equipos mediante la reparación y mantenimiento con todos los recaudos necesarios para que puedan volver a usarlas en sus proyectos. Mediante Inspecciones No Destructivas podrán continuar sus trabajos preservando la seguridad y eficiencia requerida por la actividad.",
        },
      ],
      images: [foto12, foto13, foto14, foto15, foto6, foto8, foto27, foto28, foto29],
    },
  ];

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleServiceClick = (serviceId) => {
    if (selectedService === serviceId) {
      setSelectedService(null);
    } else {
      setSelectedService(serviceId);
    }
  };

  useEffect(() => {
    if (selectedService !== null) {
      scrollToSection(servicesRef);
    }
  }, [selectedService]);

  return (
    <>
      <div className="servicesSectionTitles" ref={reference}>
        <p>SERVICIOS</p>
      </div>
      <section className="flexBoxes">
        {services.map((service) => (
          <article
            key={service.id}
            onClick={() => handleServiceClick(service.id)}
          >
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </article>
        ))}
      </section>
      {selectedService && (
        <section className="selectedService" ref={servicesRef}>
          <div className="imagesSelected">
            {services
              .find((service) => service.id === selectedService)
              .images.map((image, index) => (
                <img
                  className="imageSelectedService"
                  key={index}
                  src={image}
                  alt={`Imagen ${index + 1}`}
                />
              ))}
          </div>
          <div className="selectedServiceDetail">
            <h3>
              {services.find((service) => service.id === selectedService).title}
            </h3>
            {services
              .find((service) => service.id === selectedService)
              .descriptionService.map((part, index) => (
                <div className="selectedServiceDetailTexts" key={index}>
                  <p>{part.content}</p>
                </div>
              ))}
          </div>
        </section>
      )}
    </>
  );
};

export default ServicesSection;
