import React from 'react';
import "./Footer.css"

const Footer = () => {
  return (
    <section className='footerSection'>
        <div className="contactInfoFooter">
          <p>Telef: 299 427 7070</p>
          <p>E-mail: administracion@rcasrl.com</p>
        </div>
    </section>
  )
}

export default Footer