import React, { useRef, useState } from "react";
import "./Home.css";

import ServicesSection from "../../components/ServicesSection/ServicesSection";

import Logo from "../../assets/img/logo.webp"
import { RxHamburgerMenu } from "react-icons/rx";

const Home = () => {
  const serviceRef = useRef(null);
  const aboutUsRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
    if (isOpen === true) {
      setIsOpen(!isOpen);
    } else {
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header>
        <section className="navbarContact">
          <div className="contactInfo">
            <p>Telef: 299 427 7070</p>
            <p>E-mail: administracion@rcasrl.com</p>
          </div>
        </section>
        <nav className="navbar">
          <img src={Logo} alt="" />
          <ul className="navbarLinks">
            <li>
              <p onClick={() => scrollToSection(aboutUsRef)}>NOSOTROS</p>
            </li>
            <li>
              <p onClick={() => scrollToSection(serviceRef)}>SERVICIOS</p>
            </li>
            <li>
              <a
                href="https://goo.gl/maps/ASPNPyuPZt1docSz9"
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿DONDE NOS UBICAMOS?
              </a>
            </li>
          </ul>
          <div className="menuButton">
            <RxHamburgerMenu
              cursor={"pointer"}
              size={32}
              onClick={toggleMenu}
              color={"rgb(238 29 35)"}
            />
          </div>
          <section className={`menuLinks ${isOpen ? "open" : ""}`}>
            <ul>
              <li>
                <p onClick={() => scrollToSection(aboutUsRef)}>NOSOTROS</p>
              </li>
              <li>
                <p onClick={() => scrollToSection(serviceRef)}>SERVICIOS</p>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/ASPNPyuPZt1docSz9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ¿DONDE NOS UBICAMOS?
                </a>
              </li>
            </ul>
            <section className="menuContact">
              <div className="contactInfo">
                <p>299 427 7070</p>
                <p>administracion@rcasrl.com</p>
              </div>
            </section>
          </section>
        </nav>
      </header>
      <section className="contentHome">
        <div className="backgroundHome" />
        <h1>Soluciones industriales a tu medida</h1>
        <div className="homeButtons">
          <button
            className="buttonViewServices"
            onClick={() => scrollToSection(serviceRef)}
          >
            VER SERVICIOS
          </button>
        </div>
      </section>
      <section className="usSection" ref={aboutUsRef}>
        <article className="leftUsSection">
          <div className="usSectionTitle">
            <h2>
              <div class="titleTriangle" />
              Nosotros
            </h2>
            <p>Rojo servicios (R.C.A srl)</p>
          </div>
          <div className="usSectionParagraphs">
            <p>
              Somos una empresa Neuquina con el objetivo de dar soluciones a tu
              empresa. Compromiso, eficiencia y responsabilidad nos caracterizan
              en cada uno los trabajos que realizamos, preservando el medio
              ambiente, la seguridad y la salud ocupacional.
            </p>
            <p>
              Con personal altamente calificado y con maquinarias de última
              generación garantizamos la calidad, la confiabilidad y el estricto
              cumplimiento de plazos que demandan nuestros clientes.
            </p>
            <p>
              Venta y alquiler de herramientas, inspección, arenado, pintura y
              cercado perimetral son algunos de los servicios que ofrecemos para
              cubrir las necesidades de la industria.
            </p>
          </div>
        </article>
        <article className="rightUsSection"></article>
      </section>
      <section className="sevicesSection">
        <ServicesSection reference={serviceRef} />
      </section>
    </>
  );
};

export default Home;
